import { useState } from "react";

export const usePagination = (data) => {
  const [itemsPerPage, setItemPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = data?.slice(startIndex, endIndex);
  return {
    paginatedData,
    page,
    setPage,
    itemsPerPage,
    setItemPerPage,
  };
};
