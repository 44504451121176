import { ArrowUpIcon } from "@heroicons/react/24/solid";
import React from "react";
import down from "../../assets/img/download.png";
import up from "../../assets/img/upload.png";
export const ReceiptButton = ({ status }) => {
  return (
    <div>
      <img
        src={status === "success" ? down : up}
        className={`w-5 h-5`}
        variant="Bold"
      />
    </div>
  );
};
