import { EmptyState } from "../../../components";
import { TransactionTable } from "./TransactionTable";

export const RenderData = ({ data, initialSerialNumber, view }) => {
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No transaction history found"
        description="You have not made any transaction yet."
      />
    );
  } else {
    return (
      <TransactionTable
        data={data}
        initialSerialNumber={initialSerialNumber}
        view={view}
      />
    );
  }
};
