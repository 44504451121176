import React from "react";
import { Button } from "../../../../components";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

export const Discard = ({ showModal, navigate }) => {
  return (
    <div className="text-gray-700">
      <p className="flex items-center gap-2 mt-6">
        <ExclamationCircleIcon className="w-6 text-red-600" />
        Note changes will not be made
      </p>
      <p className="mt-1">Do you wish to continue?</p>
      <div className="flex justify-end gap-5 items-center mt-6">
        <Button
          variant="outline"
          onClick={() => {
            showModal();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            navigate("/account");
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
