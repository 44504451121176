export const truncateText = (text, length) => {
  if (typeof text !== "string") {
    return ""; // or handle differently based on your use case
  }

  if (text.length <= length) {
    return text;
  }

  return `${text.substring(0, length)}...`;
};
