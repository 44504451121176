import React from "react";
import {
  Button,
  Card,
  Container,
  Header,
  Heading,
  SubHeading,
} from "../../../components";
import { TicketForm } from "./CreateTicketForm";
import { HandleGoBack } from "../../../components/handleGoBack/HandleGoBack";

export const CreateTicket = () => {
  return (
    <div>
      <Header>
        <Heading>Create Ticket Request</Heading>
        <p className="mt-1">Log your ticket request in the form below</p>
      </Header>
      <div className="flex justify-between">
        <div className="w-[500px]">
          <Container>
            <TicketForm />
          </Container>
        </div>
        <HandleGoBack />
      </div>
    </div>
  );
};
