import { EmptyState } from "../../../components";
import { TicketTable } from "./TicketTable";
import { useNavigate } from "react-router-dom";
export const RenderData = ({ data, initialSerialNumber }) => {
  const navigate = useNavigate();
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No ticket found"
        description="You have not logged any ticket yet."
        showAction
        action={{
          label: "Create a request",
          onClick: () => navigate("/ticket/create"),
        }}
      />
    );
  } else {
    return (
      <TicketTable data={data} initialSerialNumber={initialSerialNumber} />
    );
  }
};
