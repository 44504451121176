import { Edit, Edit2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { formatDate, formatNaira } from "../../../../utils";
import {
  EllipsisHorizontalCircleIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { Dropdown } from "flowbite-react";
export const MandateTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="rounded-sm overflow-x-auto mb-4">
      <table className="min-w-full whitespace-nowrap ">
        <thead className="bg-[#FEF9F4]">
          <tr>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              S/N
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Name
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Minimum
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Maximum
            </th>

            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              NO of authorization
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Time Created
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800">
          {data?.map((item, i) => (
            <tr key={item.id}>
              <td className="px-3 py-4 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {i + 1}.
              </td>

              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item?.name}
              </td>
              <td className="px-3 py-4 text-[13px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {formatNaira(item.min_amount)}
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {formatNaira(item.max_amount)}
              </td>

              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item?.minimum_no_of_authorization_required} authorization
                required
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {formatDate(item?.createdAt)}
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-[13px]    text-primary border-b border-[#c0c0c052]">
                <div className="mr-10">
                  {" "}
                  <Dropdown
                    label={
                      <EllipsisHorizontalIcon className="h-7 w-7 text-primary" />
                    }
                    inline={true}
                    arrowIcon={false}
                  >
                    <Dropdown.Item>View details</Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        navigate(
                          `/settings/mandate-rule/edit/${item?.mandate_id}`
                        );
                      }}
                    >
                      <span>Edit mandate</span>
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
