import { useMutation } from "@tanstack/react-query";
import { TextArea, Input, Button, SubHeading } from "../../../../components";

import { useForm } from "react-hook-form";
import { roleService } from "../../../../services/role.service";

export const RoleForm = ({ refetch, showModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationKey: ["create-role"],
    mutationFn: (data) => roleService.createRoles(data),
    onSuccess: () => {
      showModal();
      refetch();
    },
  });
  const onSubmit = (data) => {
    mutate(data);
  };
  return (
    <form className="space-y-9" onSubmit={handleSubmit(onSubmit)}>
      <SubHeading>Add Role</SubHeading>
      <Input
        label="Name"
        id="name"
        {...register("name", { required: true })}
        error={errors?.name && "Name is required"}
      />
      <TextArea
        label="Description"
        id="description"
        {...register("description")}
        error={errors?.description && "Description is required"}
      />
      <Button type="submit" isFullWidth disabled={isPending}>
        Create Role
      </Button>
    </form>
  );
};
