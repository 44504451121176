import React from "react";
import { Header, Heading, SubHeading } from "../../../components";
import { useParams } from "react-router-dom";
import { HandleGoBack } from "../../../components/handleGoBack/HandleGoBack";
import { Spinner } from "../../../components/Spinner";
import { RenderDetails } from "./components/RenderList";
import { useQuery } from "@tanstack/react-query";
import { transactionService } from "../../../services/transaction.service";
import { Card } from "iconsax-react";
const TransferDetails = () => {
  const { id } = useParams();
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["transfer-id", id],
    queryFn: () => transactionService.getTransferById(id),
    enabled: !!id,
  });
  return (
    <div>
      <Header>
        <Heading>Transfer Details</Heading>
        <p className="mt-1">
          {isFetching
            ? "......"
            : data?.destination_account_name && (
                <p className="flex items-center gap-2">
                  <Card className="w-5 h-5 text-primary" />
                  {data?.destination_account_name}
                </p>
              )}
        </p>
      </Header>
      {isFetching ? (
        <p className="text-[15px] font-semibold  flex flex-col h-[50vh] items-center justify-center">
          <Spinner></Spinner>
          <SubHeading>Fetching transfer details</SubHeading>
        </p>
      ) : (
        <div>
          {Object?.keys(data)?.length !== 0 && (
            <div className="col-span-12 flex justify-end items-center">
              <HandleGoBack />
            </div>
          )}

          <RenderDetails refetchTransfer={refetch} details={data} />
        </div>
      )}
    </div>
  );
};

export default TransferDetails;
