import { Calendar, Clock, Money4, Timer, Wallet } from "iconsax-react";
import { Badge, SubHeading } from "../../../../components";
import { formatDate, formatNaira } from "../../../../utils";
import { useParams } from "react-router-dom";
export const TransactionDetailsList = ({ details }) => {
  const { id } = useParams();
  return (
    <div className="border rounded-[10px] border-lighter">
      <dl className="grid grid-cols-12 gap-4  p-6 border-b-[2px] border-gray-200">
        <div className="col-span-6">
          <dt className="text-sm  text-gray-700 text-[17px] font-medium flex items-center gap-3">
            <Money4 className="h-5 w-5" />
            <span> Transfer Amount</span>
          </dt>
          <dd className="mt-3 text-lg font-bold text-gray-900">
            {details?.amount ? formatNaira(details?.amount) : ""}
          </dd>
        </div>
        <div className="col-span-6 text-right">
          <dd className="mt-1 text-sm text-[#9FA2AB] flex items-center gap-2 ">
            <Calendar className="w-5 h-5 text-primary" />
            {formatDate(details?.created_at)}
          </dd>
        </div>
        <div className="col-span-8 mt-6">
          <dt className="text-sm font-sm text-[#9FA2AB]">Approval Status</dt>
          <dd className="mt-2 text-sm text-[#3D4355] -ml-2">
            <Badge status={details?.approval_status}>
              {details?.approval_status}
            </Badge>
          </dd>
        </div>
        <div className="col-span-4 mt-6">
          <dt className="text-sm font-medium text-[#9FA2AB]">
            Transfer Status
          </dt>
          <dd className="mt-2 text-sm text-[#3D4355]">
            <Badge status={details?.transfer_status}>
              {details?.transfer_status}
            </Badge>
          </dd>
        </div>
      </dl>

      {/* bank details */}
      <dl className="grid grid-cols-12 gap-x-4 gap-y-8 sm:grid-cols-2 p-5  relative">
        <div className="col-span-6">
          <dt className="text-sm font-medium text-[#9FA2AB]">
            Transaction Reference
          </dt>
          <dd className="mt-2 text-sm text-[#3D4355]">{details?.reference}</dd>
        </div>

        {details?.narration && (
          <div className="col-span-12">
            <dt className="text-sm font-medium text-[#9FA2AB]">Narration</dt>
            <dd className="mt-2 text-sm text-[#3D4355]">
              {details?.narration}
            </dd>
          </div>
        )}

        <div className="col-span-12">
          <dt className="text-[#3D4355] font-semibold">Beneficiary</dt>
        </div>
        <div className="col-span-12 border-b-[2px] border-gray-200"></div>
        <div className="col-span-7">
          <dt className="text-sm font-medium text-[#9FA2AB]">Account Number</dt>
          <dd className="mt-2 text-sm text-[#3D4355]">
            {details?.destination_account_number}
          </dd>
        </div>
        {details?.destination_phone_number && (
          <div className="col-span-4">
            <dt className="text-sm font-medium text-[#9FA2AB]">Phone Number</dt>
            <dd className="mt-2 text-sm text-[#3D4355]">
              {details?.destination_phone_number}
            </dd>
          </div>
        )}
      </dl>

      <dl className="grid grid-cols-12 gap-x-4 gap-y-8 p-5">
        <div className="col-span-7">
          <dt className="text-sm font-medium text-[#9FA2AB]">Account Name</dt>
          <dd className="mt-2 text-sm text-[#3D4355]">
            {details?.destination_account_name}
          </dd>
        </div>
        <div className="col-span-5">
          <dt className="text-sm font-medium text-[#9FA2AB]">Account Type</dt>
          <dd className="mt-2 text-sm text-[#3D4355] capitalize">
            {details?.destination_account_type}
          </dd>
        </div>
      </dl>
    </div>
  );
};
