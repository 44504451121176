import { useMutation, useQuery } from "@tanstack/react-query";
import { roleService } from "../../../services/role.service";
import { Button, SubHeading } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { permissionsService } from "../../../services/permissions.service";
import { useCallback, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { RenderRole } from "./RoleItem/RenderRole";

export const RoleId = () => {
  const { id: roleId } = useParams();
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const navigate = useNavigate();
  const {
    data: role,
    isFetching,
    refetch: refetchRole,
  } = useQuery({
    queryKey: ["role-by-id", roleId],
    queryFn: async () => await roleService.roleById(roleId),
    enabled: !!roleId,
  });

  const setInitialPermissions = useCallback(() => {
    if (role?.permissions) {
      setCurrentPermissions(role?.permissions.map((p) => p._id));
    }
  }, [role]);
  useEffect(() => {
    setInitialPermissions();
  }, [setInitialPermissions]);

  const { data: permissions } = useQuery({
    queryKey: ["permissions"],
    queryFn: async () => await permissionsService.getPermissions(),
  });

  const { mutate: updateRolePermissions, isPending } = useMutation({
    mutationKey: ["update-permission"],
    mutationFn: async () =>
      await roleService.updateRolePermission(roleId, currentPermissions),
    onSuccess: () => {
      refetchRole();
    },
  });

  const updatePermissions = (permission, action) => {
    if (action === "add") {
      setCurrentPermissions(
        Array.from(new Set([...currentPermissions, ...permission]))
      );
    } else {
      setCurrentPermissions(
        currentPermissions.filter((p) => !permission.includes(p))
      );
    }
  };

  return (
    <section>
      <div className="flex justify-between mt-4">
        <div className="">
          <h1 className="text-base font-semibold leading-6 text-gray-900 capitalize">
            {role?.name}
          </h1>
          <p>{role?.description ?? "Update role name and and permissions"}</p>
        </div>
        <div className="mt-4 flex items-center gap-6">
          <Button
            type="button"
            onClick={() => updateRolePermissions()}
            disabled={isPending}
          >
            Update Permissions
          </Button>
          <Button
            type="button"
            variant="danger"
            onClick={() => {
              setInitialPermissions();
              navigate("/settings/roles");
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="mt-10">
        {isFetching ? (
          <ContentLoader viewBox="0 0 380 70">
            <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
          </ContentLoader>
        ) : (
          <RenderRole
            data={role}
            permissions={permissions}
            currentPermissions={currentPermissions}
            updatePermissions={updatePermissions}
          />
        )}
      </div>
    </section>
  );
};
