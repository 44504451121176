import { PowerIcon } from "@heroicons/react/24/outline";
import { Money3, Security, Trade } from "iconsax-react";

export const data = [
  {
    time: "16/08/2023 - 10:17PM",
    event: "Initiated a transfer request",
    icon: <Trade className="w-5 h-5" />,
  },
  {
    time: "16/08/2023 - 10:17PM",
    event: "Logged in 20 minutes ago",
    icon: <PowerIcon className="w-5 h-5 text-green-600" />,
  },
  {
    time: "16/08/2023 - 10:17PM",
    event: "Authorised a transfer request",
    icon: <Security className="w-5 h-5" />,
  },
  {
    time: "16/08/2023 - 10:17PM",
    event: "Logged out 1 hour ago",
    icon: <PowerIcon className="w-5 h-5 text-red-600" />,
  },
];
