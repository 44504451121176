import React from "react";
import { AuthWrapper } from "../../../../components";
import { CreateNewpasswordForm } from "./CreateNewPasswordForm";

export const CreateNewpassword = () => {
  return (
    <div>
      <AuthWrapper title={"Enter new password"}>
        <CreateNewpasswordForm />
      </AuthWrapper>
    </div>
  );
};
