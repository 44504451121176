import { useCallback, useState } from "react";
import { ModalComp } from "../components";

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  const showModal = () => setIsShowing(!isShowing);

  const Modal = useCallback(
    (props) => (
      <ModalComp open={isShowing} setOpen={showModal} {...props}>
        {props.children}
      </ModalComp>
    ),
    [isShowing]
  );

  return {
    showModal,
    Modal,
    setIsShowing,
    isShowing,
  };
};
