import { Avatar, Card, SubHeading } from "../../../components";
import React from "react";
import { formatDate } from "../../../utils";
import {
  ChatBubbleBottomCenterIcon,
  CheckIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import Chain from "../../../assets/img/chain.webp";
import { ChevronDoubleDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowCircleDown2,
  Bubble,
  Check,
  Clock,
  Message2,
} from "iconsax-react";
export const TicketResponse = ({ ticketResponse }) => {
  return (
    <div className="lg:px-5">
      <div className="flex items-center gap-2 my-5 ml-4">
        <Bubble className="w-6 h-6 text-primary" />
        <h1 className="font-semibold">Ticket Conversations</h1>
      </div>
      <div className="flex flex-col h-[500px] mt-2 overflow-hidden overflow-y-auto side px-4">
        {ticketResponse?.map((datum, index) => (
          <div>
            <div className=" bg-white shadow-sm  px-5 py-7 rounded-s-[50px] rounded-se-[55px] border border-gray-300 relative mt-5">
              <div className="flex flex-col gap-1">
                <p className="text-[15px] font-[500] absolute -top-5 left-7 bg-white flex items-center gap-2">
                  <Avatar name={datum?.user} />
                  {datum?.user}
                </p>
                <p className="text-[14px]  text-[#70737a] pb-3">
                  {datum?.message}
                </p>
                <p className="text-[13px]  text-[#9FA2AB] absolute bottom-2 right-2 flex items-center gap-2 ">
                  <Clock variant="Bold" className="w-4 h-4 text-primary" />
                  {formatDate(datum?.createdAt?.seconds * 1000)}
                  <div className="flex items-center">
                    <CheckIcon className="w-4 h-4 text-blue-500" />
                  </div>
                </p>
              </div>
            </div>
            {index !== ticketResponse?.length - 1 && (
              <div className="justify-center flex flex-col items-center my-5">
                <ArrowCircleDown2 className="w-6 h-6 text-green-600" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
