import React from "react";
import { Header, Heading, SubHeading } from "../../../components";
import { Edit2 } from "iconsax-react";
import { Link } from "react-router-dom";
import { SecurityForm } from "./components/SecurityForm";
export const Security = () => {
  return (
    <div>
      <Header>
        <Heading>Account Settings/security</Heading>
      </Header>
      <div className="w-[600px]">
        <SecurityForm />
      </div>
    </div>
  );
};
