import React from "react";
import { ProfileUpdate } from "./components/ProfileUpdate";

export const Profile = () => {
  return (
    <div>
      <ProfileUpdate />
    </div>
  );
};
