import { EmptyState } from "../../../../components";
import { RoleTable } from "./Roletable";
export const RenderData = ({ data, initialSerialNumber, showModal }) => {
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No Roles created"
        description="You have not created any roles yet."
        showAction
        action={{
          label: "Add role",
          onClick: () => showModal(),
        }}
      />
    );
  } else {
    return <RoleTable data={data} initialSerialNumber={initialSerialNumber} />;
  }
};
