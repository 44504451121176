import { EmptyState } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { TransactionDetailsList } from "./TransactionDetailsList";
import Role from "./Role";
import { ArrowLeft } from "iconsax-react";
export const RenderDetails = ({ details, refetchTransfer }) => {
  const navigate = useNavigate();
  if (Object?.keys(details)?.length === 0) {
    return (
      <EmptyState
        title="No transfer details found"
        description="Please check your internet connection or reload."
        showAction
        Icon={ArrowLeft}
        action={{
          label: "go back",
          onClick: () => navigate("/transfer-request"),
        }}
      />
    );
  } else {
    return (
      <div className="grid grid-cols-12 gap-10 mt-12 lg:mt-5">
        <div className="lg:col-span-6 col-span-12">
          <TransactionDetailsList details={details} />
        </div>
        <div className="lg:col-span-4 col-span-12">
          <Role detail={details} refetchTransfer={refetchTransfer} />
        </div>
      </div>
    );
  }
};
