import { useForm } from "react-hook-form";
import { Button, Input, SubHeading } from "../../../../components";
import { Select } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { mandateRuleService } from "../../../../services/mandate.service";
import { useRoles } from "../../Roles/hook/useRoles";
export const CreateMandateForm = ({
  defaultValues = {},
  type = "create",
  id,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  const navigate = useNavigate();
  const { data } = useRoles();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) =>
      type === "create"
        ? mandateRuleService.createMandateRule(data)
        : mandateRuleService.updateMandateRule(id, data),
    onSuccess: () => {
      navigate("/settings/mandate-rule");
    },
  });
  const onSubmit = (data) => {
    const payload = {
      min_amount: data?.min_amount,
      max_amount: data?.max_amount,
      role_id: data?.role?.value,
      name: data?.name,
      minimum_no_of_authorization_required: data?.min_authorization,
    };
    mutate(payload);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-10">
      <div className="mb-5">
        {type === "create" ? (
          <SubHeading>Create a new mandate rule</SubHeading>
        ) : (
          <SubHeading>Edit {defaultValues?.name}</SubHeading>
        )}
      </div>
      <Input
        label="Mandate name"
        id="name"
        {...register("name", { required: true })}
        error={errors.name && "Name is required"}
      />
      <Input
        label="Min amount"
        id="min_amount"
        disabled={type !== "create"}
        {...register("min_amount", { required: true })}
        error={errors.min_amount && "Min amount is required"}
      />
      <Input
        label="Max amount"
        id="max_amount"
        disabled={type !== "create"}
        {...register("max_amount", { required: true })}
        error={errors.max_amount && "Max amount is required"}
      />
      <Input
        label="Minimum number of authorization"
        id="min_authorization"
        disabled={type !== "create"}
        {...register("min_authorization", { required: true })}
        error={
          errors.min_authorization &&
          "Minimum number of authorization is required"
        }
      />

      <Select
        name="role"
        label="Role"
        control={control}
        options={data?.map((role) => ({
          label: role?.name,
          value: role?.role_id,
        }))}
        error={errors?.role && "Role is required"}
      />
      <div className="pt-2 flex lg:justify-end justify-start">
        <Button disabled={isPending} type="submit" variant="primary">
          {type === "create" ? "Create mandate rule" : "Update mandate rule"}
        </Button>
      </div>
    </form>
  );
};
