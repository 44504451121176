import { Calendar, Calendar1, Calendar2, Eye } from "iconsax-react";
import { Badge } from "../Badge/Badge";
import { formatNaira } from "../../utils";
import { useModal } from "../../hooks/useModal";
import { ReceiptButton } from "../Button/ReceiptButton";
import { useNavigate } from "react-router-dom";
const Table = ({
  columns,
  data,
  viewBtn = true,
  type,
  receiptChild,
  to,
  viewText = "view more",
  Icon = Eye,
  initialSerialNumber,
}) => {
  const { Modal, showModal } = useModal();
  const navigate = useNavigate();
  return (
    <div className="rounded-sm overflow-hidden overflow-x-auto mb-4">
      <table className="min-w-full ">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              S/N
            </th>
            {type && (
              <th className="px-6 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
                Receipt
              </th>
            )}
            {columns?.map((column) => (
              <th
                key={column.key}
                className="px-6 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]"
              >
                {column.label}
              </th>
            ))}
            {viewBtn === true && (
              <th className="px-6 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800">
          {data?.map((item, i) => (
            <tr key={item.id}>
              <td className="px-6 py-4 text-left text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {initialSerialNumber + i}.
              </td>
              {type && (
                <td className="px-6 text-left py-4 whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  <ReceiptButton onClick={showModal} />
                </td>
              )}
              {columns.map((column, i) => (
                <td
                  key={column.key}
                  className="px-6 py-4 text-left whitespace-nowrap text-[13px] text-[#6E6B7B] font-[200]  border-b border-[#c0c0c052] capitalize"
                >
                  {column.key === "status" || column.key === "transfers" ? (
                    <Badge status={item[column.key]}>{item[column.key]}</Badge>
                  ) : column.key === "amount" ? (
                    formatNaira(item[column.key])
                  ) : column.key === "time" ? (
                    <div className="flex items-center gap-2">
                      <Calendar2
                        variant="Bold"
                        className="w-5 h-5 text-primary"
                      />
                      {item[column.key]}
                    </div>
                  ) : (
                    item[column.key]
                  )}
                </td>
              ))}
              {viewBtn === true && (
                <td className="px-6 py-4 text-left whitespace-nowrap text-sm  text-primary border-b border-[#C0C0C0]">
                  <div
                    onClick={() => {
                      navigate(to);
                    }}
                    className="flex items-center gap-3 font-semibold cursor-pointer"
                  >
                    <Icon className="w-4 h-4" variant="Bold" />
                    <span>{viewText}</span>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {Modal({
        children: <>{receiptChild}</>,
        showCloseIcon: true,
        size: "sm",
      })}
    </div>
  );
};

export default Table;
