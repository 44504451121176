import React, { useState } from "react";
import { Chrome } from "@uiw/react-color";

function Picker({ hex, setHex, onChange }) {
  return (
    <div className="grid grid-cols-12  bg-white p-3">
      <div className="lg:col-span-6 col-span-12">
        <Chrome color={hex} onChange={onChange} />
      </div>
    </div>
  );
}

export default Picker;
