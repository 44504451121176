import { useMemo, useState } from "react";

export const Greeting = ({ name }) => {
  const [greeting, setGreeting] = useState("");
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  useMemo(() => {
    if (currentHour < 12) {
      setGreeting("Good Morning");
    } else if (currentHour < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, [currentHour]);

  return (
    <h1>
      {greeting === undefined ? "...." : greeting}, {name}
    </h1>
  );
};
