import { Link } from "react-router-dom";
import { Container, SubHeading } from "../../../components";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import { Add } from "iconsax-react";
import { useRoles } from "./hook/useRoles";
import ContentLoader from "react-content-loader";
import { RenderData } from "./components/Renderdata";
import { useModal } from "../../../hooks";
import { RoleForm } from "./components/RoleForm";

export const Roles = () => {
  const { data, isFetching, refetch } = useRoles();
  const { Modal, showModal } = useModal();

  return (
    <div>
      <div className="flex flex-col lg:flex-row lg:items-center item-start justify-between lg:mb-3 mb-5">
        <SubHeading>List of roles and permissions</SubHeading>
        <p
          onClick={() => {
            showModal();
          }}
          className="flex cursor-pointer items-center gap-2 text-[14px] font-semibold text-primary border-[1px] border-light px-3 py-2 rounded-2xl w-fit"
        >
          <Add className="w-5 h-5" />
          <span>Add role</span>
        </p>
      </div>
      {isFetching ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <Container>
          <RenderData showModal={showModal} data={data} />
        </Container>
      )}
      {Modal({
        children: <RoleForm showModal={showModal} refetch={refetch} />,
      })}
    </div>
  );
};
