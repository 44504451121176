import { ArrowDown2, ArrowUp2, Calendar1, Eye } from "iconsax-react";
import { Avatar, Badge, ReceiptButton } from "../../../components";
import { formatDate, formatNaira, truncateText } from "../../../utils";
import { useNavigate } from "react-router-dom";
export const TransferTable = ({ data, initialSerialNumber }) => {
  const navigate = useNavigate();
  return (
    <div className="rounded-sm overflow-hidden overflow-x-auto mb-4">
      <table className="min-w-full ">
        <thead>
          <tr>
            <th className="pl-1 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[11px]">
              S/N
            </th>

            <th className="pl-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[11px]">
              Beneficiary
            </th>
            <th className="pl-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[11px]">
              Account type
            </th>
            <th className="pl-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[11px]">
              Amount
            </th>

            <th className="pl-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[11px]">
              Transaction date
            </th>
            <th className="pl-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[11px]">
              Reference
            </th>

            <th className="pl-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[11px]">
              Transfer status
            </th>
            <th className="pl-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[11px]">
              Approval status
            </th>
            <th className="pl-2 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[11px]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800">
          {data?.map((item, i) => (
            <tr key={i}>
              <td className="px-1 py-4 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {initialSerialNumber + i}
              </td>

              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div>
                  <p className="mb-3">{item?.destination_account_name}</p>
                  <p className="mt-2"> {item?.destination_account_number}</p>
                </div>
              </td>
              <td className="px-3 py-4 text-[13px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div>
                  <p className="mb-2 capitalize">
                    {item?.destination_account_type}
                  </p>
                </div>
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {formatNaira(item?.amount)}
              </td>

              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div className="flex items-center gap-2">
                  <Calendar1 variant="Bold" className="w-5 h-5 text-primary" />
                  {formatDate(item?.created_at)}
                </div>
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div className="flex items-center gap-2">{item?.reference}</div>
              </td>

              <td className="px-3 py-4 text-[12px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <Badge status={item?.transfer_status}>
                  {item?.transfer_status}
                </Badge>
              </td>
              <td className="px-3 py-4 text-[12px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <Badge status={item?.approval_status}>
                  {item?.approval_status}
                </Badge>
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-[13px]    text-primary border-b border-[#c0c0c052]">
                <div
                  onClick={() => {
                    navigate(`/transfer-request/details/${item?.id}`);
                  }}
                  className="flex items-center gap-3 font-semibold cursor-pointer"
                >
                  <span className="px-3 py-1 border   rounded-md">
                    view details
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
