import React, { useMemo } from "react";
import { CreateMandateForm } from "./CreateMandateForm";
import { Container, Header, Heading, SubHeading } from "../../../../components";
import { HandleGoBack } from "../../../../components/handleGoBack/HandleGoBack";
import { useParams } from "react-router-dom";
import { mandateRuleService } from "../../../../services/mandate.service";
import { useQuery } from "@tanstack/react-query";
import { GridLoader } from "react-spinners";

export const CreateMandate = () => {
  const { mandate_id: id } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["get-single-mandateRule", id],
    queryFn: () => mandateRuleService.getSingleMandateRule(id),
    enabled: !!id,
    cacheTime: 0,
  });

  const defaultValues = useMemo(() => {
    if (data) {
      return {
        min_amount: data?.min_amount?.toString() ?? "",
        max_amount: data?.max_amount?.toString() ?? "",
        name: data?.name ?? "",
        min_authorization: data?.minimum_no_of_authorization_required ?? "",
      };
    }
  }, [data]);
  return (
    <div className="w-[80%] lg:w-full">
      <div className="flex items-center">
        <Header>
          <Heading>
            {!id
              ? "Account Settings/create-mandate"
              : "Account Settings/edit-mandate"}
          </Heading>
        </Header>
      </div>
      <div className="flex justify-between items-center mb-5">
        <div>
          {!id ? (
            <SubHeading>New mandate rule </SubHeading>
          ) : (
            <SubHeading>Edit mandate rule </SubHeading>
          )}
          <p className="mt-1 text-sm leading-6 text-gray-500">
            {!id
              ? " Create a new mandate rule with the form below."
              : " Edit mandate rule with the form below."}
          </p>
        </div>
        <HandleGoBack />
      </div>
      <div className="w-[400px] lg:w-[500px]">
        <Container>
          {!id ? (
            <CreateMandateForm type={"create"} />
          ) : (
            <div>
              {isFetching ? (
                <GridLoader size={15} color={"#e78020"} />
              ) : (
                <div>
                  {data && (
                    <CreateMandateForm
                      id={id}
                      type={id}
                      defaultValues={defaultValues}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};
