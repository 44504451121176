import React from "react";
import Table from "../../../components/Table/Table";

export const AuditTable = ({ data, columns, initialSerialNumber }) => {
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        viewBtn={false}
        initialSerialNumber={initialSerialNumber}
      />
    </div>
  );
};
