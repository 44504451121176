import { Edit } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { formatNaira } from "../../../../utils";
export const RoleTable = ({ data, initialSerialNumber }) => {
  const navigate = useNavigate();
  return (
    <div className="rounded-sm overflow-hidden overflow-x-auto mb-4">
      <table className="min-w-full ">
        <thead className="bg-[#FEF9F4]">
          <tr>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              S/N
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Name
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Slug
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Permissions
            </th>

            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800">
          {data?.map((item, i) => (
            <tr key={item.id}>
              <td className="px-3 py-4 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#C0C0C0]">
                {initialSerialNumber ? initialSerialNumber + i : i + 1}.
              </td>

              <td className="px-3 py-4 text-[13px] capitalize  whitespace-nowrap text-[#6E6B7B] border-b border-[#C0C0C0]">
                {item?.name}
              </td>
              <td className="px-3 py-4 text-[13px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#C0C0C0]">
                {item?.name}
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#C0C0C0]">
                {item?.permissions?.length}
              </td>

              <td className="px-3 py-4 whitespace-nowrap text-[13px]    text-primary border-b border-[#C0C0C0]">
                <div
                  onClick={() => {
                    navigate(`/settings/roles/${item?._id}`);
                  }}
                  className="flex items-center gap-3 font-semibold cursor-pointer w-fit px-3 py-1 border  border-lighter rounded-full"
                >
                  <Edit className="w-5 h-5" />
                  <p>View</p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
