export const columns = [
  { key: "event", label: "Event" },
  { key: "user", label: "User" },
  { key: "feedback", label: "Action" },
  { key: "time", label: "Date & Time" },
];
export const AuditData = [
  {
    id: 1,
    event: "Authorisation",
    user: "Chukwudi Samuel",
    feedback: "Chukwudi authorised a transfer on 13  August 2023 8:32 AM",
    time: "16/08/2023 - 10:17PM",
  },
  {
    id: 2,
    event: "Transaction",
    user: "Chukwudi Samuel",
    feedback: "Chukwudi made a transfer on  13  August 2023 9:30 AM",
    time: "16/08/2023 - 10:17PM",
  },
  {
    id: 3,
    event: "Authorisation",
    user: "Chukwudi Samuel",
    feedback: "Chukwudi authorised a transfer on  13  August 2023 11:32 AM",
    time: "16/08/2023 - 10:17PM",
  },
  {
    id: 4,
    event: "Transaction",
    user: "Chukwudi Samuel",
    feedback: "Chukwudi made a transfer on  13  August 2023 12:11 PM",
    time: "16/08/2023 - 10:17PM",
  },
  {
    id: 5,
    event: "Authorisation",
    user: "Chukwudi Samuel",
    feedback: "Chukwudi authorised a transfer on  14  August 2023 07:11 AM",
    time: "16/08/2023 - 10:17PM",
  },
  {
    id: 6,
    event: "Transaction",
    user: "Chukwudi Samuel",
    feedback: "Chukwudi made a transfer on  14  August 2023 09:11 PM",
    time: "16/08/2023 - 10:17PM",
  },
];
