import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

export const useClickOutside = () => {
  const dropdownRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return {
    handleClickOutside,
    dropdownRef,
    isOpen,
    setIsOpen,
  };
};
