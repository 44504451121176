import React from "react";
import { AuthWrapper } from "../../../../components";
import { VerifyOtpForm } from "./VerifyOtpForm";

const VerifyOtp = () => {
  return (
    <div>
      <AuthWrapper title="Enter OTP">
        <VerifyOtpForm />
      </AuthWrapper>
    </div>
  );
};

export default VerifyOtp;
