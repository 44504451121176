import React from "react";
import { AuthWrapper } from "../../../components";
import { ForgotPasswordForm } from "./ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <div>
      <AuthWrapper title={"Recover Password"}>
        <ForgotPasswordForm />
      </AuthWrapper>
    </div>
  );
};

export default ForgotPassword;
