import React, { useState } from "react";
import { Button, TextArea } from "../../../../components";
import { Badge } from "../../../../components/Badge/Badge";
import { useModal } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { transactionService } from "../../../../services/transaction.service";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../utils";
import { ArrowCircleDown } from "iconsax-react";
const AuthoriseForm = ({
  title,
  refetch,
  detail,
  decision,
  showModal,
  refetchTransfer,
}) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => transactionService.handleDecision(data, decision),
    onSuccess: () => {
      refetch();
      refetchTransfer();
      showModal();
    },
  });
  const onSubmit = (data) => {
    mutate({
      reason: data?.response,
      transfer_request_id: detail?.id,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <p className="text-md font-semibold">{title}</p>
      <TextArea
        id="response"
        label="Reason"
        {...register("response", { required: true })}
        error={errors.response && "Reason is required"}
      />
      <div className="flex justify-end">
        <Button disabled={isPending} type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
};
const Role = ({ detail, refetchTransfer }) => {
  const { Modal: Approve, showModal: showApprove } = useModal();
  const { Modal: Reject, showModal: showReject } = useModal();
  const [decision, setDecision] = useState("");
  const navigate = useNavigate();

  const params = {
    transfer_request_id: detail?.id,
  };
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["get-decisions", params],
    queryFn: () => transactionService.getDecisions(params),
    enabled: !!detail?.id,
  });
  return (
    <div>
      {detail?.approval_status === "pending" && (
        <div className=" flex justify-end items-center gap-14">
          <Button
            type="button"
            onClick={() => {
              showApprove();
              setDecision("approve");
            }}
            variant="success"
          >
            Approve
          </Button>
          <Button
            type="button"
            onClick={() => {
              showReject();
              setDecision("reject");
            }}
            variant="danger"
          >
            Decline
          </Button>
        </div>
      )}
      {data?.map((item, i) => (
        <>
          <div
            key={i}
            className="p-5  grid grid-cols-12 gap-4 border-2 rounded-2xl border-primary"
          >
            <p className="col-span-6 text-gray-700 text-[15px]">Decision:</p>
            <p className="col-span-6 text-gray-400">{item.decision}</p>
            <p className="col-span-6">Reason</p>
            <p className="col-span-6 text-gray-400 text-[15px]">
              {item?.reason}
            </p>
            <p className="col-span-6">Time created</p>
            <p className="col-span-6 text-gray-400 text-[15px]">
              {formatDate(item?.created_at)}
            </p>
            <p className="col-span-6">Time updated</p>
            <p className="col-span-6 text-gray-400 text-[15px]">
              {formatDate(item?.updated_at)}
            </p>
            <p className="col-span-6">Approval status</p>
            <div className="col-span-6">
              <Badge status={item?.transfer_request?.approval_status}>
                {item?.transfer_request?.approval_status}
              </Badge>
            </div>
          </div>
          {i !== data?.length - 1 && (
            <ArrowCircleDown
              className="mx-auto mt-2 text-primary"
              variant="Bold"
            />
          )}
        </>
      ))}
      {Approve({
        children: (
          <div>
            <AuthoriseForm
              detail={detail}
              title={"Approve Transaction"}
              refetch={refetch}
              decision={decision}
              showModal={showApprove}
              refetchTransfer={refetchTransfer}
            />
          </div>
        ),
      })}
      {Reject({
        children: (
          <div>
            <AuthoriseForm
              detail={detail}
              title={"Reject Transaction"}
              refetch={refetch}
              decision={decision}
              showModal={showReject}
              refetchTransfer={refetchTransfer}
            />
          </div>
        ),
      })}
    </div>
  );
};

export default Role;
